export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: 'home-page',
  SECTION_PAGE: 'section-page',
  VIDEOS_PAGE: 'videos-page',
  PLAYLIST_PAGE: 'playlist-page',
  COLLECTION_PAGE: 'collection-page',
  TAG_PAGE: 'tag-page',
  SEARCH_PAGE: 'search-page',
  STORY_PAGE: 'story-page',
  CATALOG_PAGE: 'catalog-page',
  STORY_PUBLIC_PREVIEW_PAGE: 'story-public-preview-page',
  STORY_PREVIEW: 'story-preview',
  HOME_PREVIEW: 'home-preview',
  STATIC_PAGE: 'static-page',
  SITEMAP_PAGE: 'sitemap',
  FORM_PAGE: 'form-page',
  AUTHOR_PAGE: 'author-page',
  RESET_PASSWORD_PAGE: 'reset-password-page',
  PROFILE_PAGE: 'profile-page',
  USER_LOGIN: 'user-login',
  VISUAL_STORY: 'visual-story',
  PWA_SHELL: 'shell',
  ABOUT_US: 'about-us',
  CALCULATORS: 'calculators',
  INCOME_TAX: '/calculators/income-tax',
  STATE_OF_THE_ECONOMY_FULL_WIDGET: 'widget-state-of-the-economy'
});
export const TAG_PAGE_URL_PREFIX = '/topic/';
export const storyFields =
  'headline,subheadline,sections,author-name,authors,hero-image-metadata,hero-image-s3-key,slug,id,title';

export const DATE_FORMATS = Object.freeze({
  DATE_TIME: 'MMMM dd yyyy, h:mm aa',
  DATE_TIME_INDIAN: 'dd MMMM yyyy, h:mm aa',
  DATE_TIME_SHORT_MONTH: 'MMM dd yyyy, h:mm aa',
  HOUR_MINUTE: 'h:mm a',
  MONTH_DATE_HOUR_MINUTE: 'MMMM dd, h:mm a',
  HOUR_MINUTE_MONTH_DATE: 'h:mm aa, MMMM dd',
  HOUR_MINUTE_DATE_MONTH_YEAR: 'h:mm aa, dd MMM yyyy',
  MONTH_DATE_HOUR_MINUTE_MERIDIEM: 'MMMM dd, h:mm aa',
  HOUR_MINUTE_MERIDIEM: 'hh:mm aa',
  DATE_FULLMONTH_YEAR: 'dd MMMM yyyy',
  FULLMONTH_DATE_YEAR: 'MMMM dd yyyy',
  MONTH_DATE_YEAR: 'MMM dd, yyyy',
  DATE_MONTH_YEAR: 'dd MMM yyyy',
  DATE_TIME_ZONE: 'yyyy-MM-dd HH:mm:ssxxx'
});

export const STRINGS = {
  COPYRIGHT_TEXT: `${new Date().getFullYear()} NDTV Profit हिंदी. All rights reserved`,
  NEW_COPYRIGHT_TEXT: `NDTV Profit. All Rights Reserved`,
  GET_YEAR: `${new Date().getFullYear()}`,
  DISCLAIMER_TEXT: `The BLOOMBERG trademark and service mark is owned by Bloomberg Finance L.P., a Delaware limited partnership, or its subsidiaries. Bloomberg does not own or control the platform on which this mark appears and its appearance here is pursuant to a trademark license agreement and does not imply any endorsement of the platform or its owners. For the avoidance of doubt, any content on this platform that does not carry a “Bloomberg” byline was not authored by, and is not controlled by, Bloomberg nor does it reflect Bloomberg’s views or opinions.`,
  SUBSCRIBE: 'Subscribe',
  SUBSCRIBE_NOW: 'अंग्रेज़ी पर स्विच करें',
  SUBSCRIBE_WEEKELY: 'Subscribe @ ₹48/week',
  EXPLORE_PLANS: 'Explore Plans',
  FOLLOW_US: 'FOLLOW US',
  NEW_FOLLOW_US_TITLE: 'FOLLOW NDTV PROFIT हिंदी ON',
  NOW_PLAYING: 'NOW PLAYING',
  MUST_WATCH_TITLE: 'MUST WATCH',
  MUST_WATCH_SUB_TITLE: 'New Webcasts from Business Experts Everyday',
  EDITORIAL_FEEDBACK: 'Editorial Feedback',
  SALES_QUERIES: 'Sales',
  SUPPORT: 'Support',
  MESSAGE_US: 'Message Us',
  INQUIRE_NOW: 'Inquire Now',
  GET_IN_TOUCH: 'Get in Touch',
  SALES: 'Sales',
  JOIN: 'Join our',
  FOR_ANY_COMPLAINTS: 'किसी भी शिकायत के लिए',
  OFFICIAL_CHANNELS: 'Official Channels',
  RESEARCH_REPORTS_SUBTITLE:
    'NDTV Profit special research section collates quality and in-depth equity and economy research reports from across India’s top brokerages, asset managers and research agencies.',
  LEGAL_REPORTS_SUBTITLE:
    'NDTV Profit special Legal Reports section collates quality and in-depth equity and economy legal reports from across India’s top brokerages, asset managers and research agencies.'
};

export const SOCIAL_SHARE_OLD = {
  TELEGRAM: 'https://t.me/bqprime',
  WEBSITE_NOTIFICATION: 'https://www.ndtvprofit.com/subscribe-push-notification',
  NEWSLETTER: '/my-bq/newsletter',
  PODCAST: 'https://soundcloud.com/bqprime',
  WHATSAPP: 'https://widget.msgp.pl/?id=58585dea56eeb8a3a0.79725181',
  LINKEDIN: 'https://www.linkedin.com/company/13184554',
  YOUTUBE: 'https://www.youtube.com/channel/UC3uJIdRFTGgLWrUziaHbzrg',
  TWITTER: 'https://twitter.com/BQPrime',
  INSTAGRAM: 'https://instagram.com/bqprime',
  FACEBOOK: 'https://en-gb.facebook.com/BQPrime'
};

export const SOCIAL_SHARE = {
  TELEGRAM: 'https://t.me/ndtvprofithindi',
  WHATSAPP: '/icons/whatsapp',
  WEBSITE_NOTIFICATION: 'https://www.ndtvprofit.com/subscribe-push-notification',
  NEWSLETTER: '/my-bq/newsletter',
  PODCAST: 'https://soundcloud.com/bqprime',
  LINKEDIN: 'https://www.linkedin.com/company/13184554',
  YOUTUBE: 'https://www.youtube.com/channel/UCiThge5Gve5Syt299i2bsxA',
  TWITTER: 'https://twitter.com/NDTVProfitHindi',
  INSTAGRAM: 'https://www.instagram.com/ndtvprofithindi',
  FACEBOOK: 'https://www.facebook.com/profithindindtv',
  GOOGLE_NEWS:
    'https://news.google.com/publications/CAAqBwgKMOSpmQswhLSxAw?hl=en-IN&gl=IN&ceid=IN:en'
};

export const PATH = Object.freeze({
  ENGLISH_SITE: 'https://ndtvprofit.com?src=bqprimehindi',
  MYBQ_PAGE: '/my-bq',
  MYBQ_PAGE_TABS: '/my-bq/:tabs',
  MY_PORTFOLIO_PAGE: '/my-portfolio',
  SUBSCRIPTION_PAGE: '/bq-blue-subscription',
  SITEMAP_PAGE: '/sitemap',
  AUTHOR_PAGE: '/author/:id/:slug',
  AUTHORS_PAGE: '/authors',
  TAG_PAGE: '/topic/:tagSlug',
  SEARCH_PAGE: '/search',
  JUSPAY_RESPONSE: '/juspay-response',
  MYBQ_REFERRAL: '/free-subscription',
  MYBQ_COUPONS: '/coupons',
  MYBQ_PERSONALISED: '/myBQ',
  MYBQ_LOYALTY: '/loyalty',
  MYBQ_STOCK_WATCHLIST: '/my-watchlist',
  MYBQ_ACCOUNT: '/account',
  MYBQ_NEWSLETTER: '/newsletter',
  SUBSCRIBE_PUSH_NOTIFICATION: '/subscribe-push-notification',
  LOGIN: '/user-login',
  SIGN_UP: '/user-sign-up',
  SIGN_UP_SUCCESS: '/reset-password',
  HOME: '/',
  BQ_BLUE_EXCLUSIVE: '/bq-blue-exclusive',
  TODAYS_LATEST: '/the-latest',
  BUSINESS: '/business',
  MARKETS: '/markets',
  NIFTY_FUTURES: '/markets/futures-contract',
  ECONOMY_FINANCE: '/economy-finance',
  LAW_POLICY: '/law-and-policy',
  VIDEOS: '/videos',
  PERSONAL_FINANCE: '/personal-finance',
  OPINION: '/opinion',
  POLITICS: '/politics',
  BQ_LEARNING: '/bqlearning',
  TECHNOLOGY: '/technology',
  PURSUIT: '/pursuits',
  ABOUT_US: '/about-us',
  CALCULATORS: '/calculators',
  INCOME_TAX: '/calculators/income-tax',
  TERMS_OF_USE: 'https://www.ndtvprofit.com/terms-of-use',
  PRIVACY_POLICY: 'https://www.ndtvprofit.com/privacy-policy',
  RSS: '/stories.rss',
  PARTNERS_PAGE: '/bq-partners',
  RESEARCH_REPORTS_PAGE: '/bq-blue-subscription/research-report-page',
  SUBSCRIPTION_PAGE_IPO: '/bq-blue-subscription/ipo',
  SUBSCRIPTION_PAGE_DIWALI: '/bq-blue-subscription/diwali',
  BQ_EDGE_EVENTS_DIWALI: '/bq-edge-events/diwali',
  AUTHORS: '/authors',
  EDITORIAL_CODE_OF_CONDUCT: '/editorial-code-of-conduct',
  STATE_OF_THE_ECONOMY_FULL_WIDGET: '/widget/state-of-the-economy'
});

export const CONTACT = {
  SUPPORT_ID: 'hindi.profitsupport@ndtv.com',
  EDITOR_ID: 'hindi.profiteditor@ndtv.com',
  SALES_ID: 'hindi.profitsales@ndtv.com',
  CONTACT_ID: 'Get In Touch'
};

export const TYPEFORM_LINKS = {
  CONTACT_FORM: 'https://bqprime.typeform.com/to/SFYms9mY',
  STUDENT_SUB_FORM: 'https://bqprime.typeform.com/to/yJQBVn9N',
  TEAM_SUB_FORM: 'https://bqprime.typeform.com/to/pydgIuG0'
};

export const EXTERNAL = {
  BQ_BRAND_STUDIO: 'http://www.bqbrandstudio.com',
  BLOOMBERG_QUINT: 'https://www.ndtvprofit.com',
  BLOOMBERG: 'https://www.bloomberg.com',
  QUINT: 'https://thequint.com'
};

export const BLOOMBERGQUINT_FOOTER_LINKS = {
  header: 'NDTV Profit हिंदी',
  links: [
    {
      name: 'Exclusives',
      href: PATH.BQ_BLUE_EXCLUSIVE,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: `Today's Latest`,
      href: PATH.TODAYS_LATEST,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Business',
      href: PATH.BUSINESS,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Markets',
      href: PATH.MARKETS,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Nifty Futures',
      href: PATH.NIFTY_FUTURES,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Economy & Finance',
      href: PATH.ECONOMY_FINANCE,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Law & Policy',
      href: PATH.LAW_POLICY,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Videos',
      href: PATH.VIDEOS,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Personal Finance',
      href: PATH.PERSONAL_FINANCE,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Opinion',
      href: PATH.OPINION,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Politics',
      href: PATH.POLITICS,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Learning',
      href: PATH.BQ_LEARNING,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Technology',
      href: PATH.TECHNOLOGY,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Pursuits',
      href: PATH.PURSUIT,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Brand Studio',
      href: EXTERNAL.BQ_BRAND_STUDIO,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  ]
};

export const GET_REG_UPDATES_FOOTER_LINKS = {
  header: 'Video links',
  links: [
    {
      name: 'Telegram',
      href: SOCIAL_SHARE.TELEGRAM,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Newsletter',
      href: SOCIAL_SHARE.NEWSLETTER,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Podcasts',
      href: SOCIAL_SHARE.PODCAST,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Authors',
      href: `${PATH.AUTHORS}?src=auth_ftr`,
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    {
      name: 'Code of Conduct',
      href: `${PATH.EDITORIAL_CODE_OF_CONDUCT}`,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  ]
};

export const SOCIAL_ICONS = [
  // {
  //   NAME: 'WHATSAPP',
  //   URL: SOCIAL_SHARE.WHATSAPP,
  //   icon_code: 'whatsapp_grey_bg'
  // },
  // {
  //   name: 'LinkedIn',
  //   url: SOCIAL_SHARE.LINKEDIN,
  //   icon_code: 'linkedin_grey_bg'
  // },
  {
    name: 'Youtube',
    url: SOCIAL_SHARE.YOUTUBE,
    icon_code: 'youtube_grey_bg'
  },
  {
    name: 'Twitter',
    url: SOCIAL_SHARE.TWITTER,
    icon_code: 'twitter_bg_grey'
  },
  {
    name: 'Instagram',
    url: SOCIAL_SHARE.INSTAGRAM,
    icon_code: 'instagram_grey'
  },
  {
    name: 'Telegram',
    url: SOCIAL_SHARE.TELEGRAM,
    icon_code: 'telegram_grey_bg'
  },
  {
    name: 'Facebook',
    url: SOCIAL_SHARE.FACEBOOK,
    icon_code: 'facebook_grey_bg'
  }
];

export const COPYRIGHT_LINKS = [
  {
    title: 'About Us',
    url: PATH.ABOUT_US,
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: 'Terms of Use',
    url: PATH.TERMS_OF_USE,
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: 'Privacy Policy',
    url: PATH.PRIVACY_POLICY,
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: 'Site Map',
    url: PATH.SITEMAP_PAGE,
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  // {
  //   title: 'Code of Conduct',
  //   url: PATH.EDITORIAL_CODE_OF_CONDUCT,
  //   target: '_blank',
  //   rel: 'noopener noreferrer'
  // },
  {
    title: 'RSS',
    url: PATH.RSS,
    target: '_blank',
    rel: 'noopener noreferrer'
  }
];

export const FOOTER_CONTACT_US = {
  CONTACT_ID: 'Get In Touch',
  CONTACT_LIST: [
    {
      link: `mailto:${CONTACT.EDITOR_ID}`,
      path: '/icons/editor.png',
      title: 'Editorial Feedback',
      email: 'hindi.profiteditor@ndtv.com'
    },
    {
      link: `mailto:${CONTACT.SALES_ID}`,
      path: '/icons/sales.png',
      title: 'Sales',
      email: 'hindi.profitsales@ndtv.com'
    },
    {
      link: `mailto:${CONTACT.SUPPORT_ID}`,
      path: '/icons/complaints.png',
      title: 'Support',
      email: 'Get In Touch'
    }
  ]
};

export const SOCIAL_ICONS_NEW = [
  {
    name: 'Youtube',
    url: SOCIAL_SHARE.YOUTUBE,
    icon_code: 'youtube_white'
  },
  {
    name: 'Twitter',
    url: SOCIAL_SHARE.TWITTER,
    icon_code: 'twitter_white'
  },
  {
    name: 'Instagram',
    url: SOCIAL_SHARE.INSTAGRAM,
    icon_code: 'instagram_white'
  },
  {
    name: 'Telegram',
    url: SOCIAL_SHARE.TELEGRAM,
    icon_code: 'telegram_white'
  },
  {
    name: 'Facebook',
    url: SOCIAL_SHARE.FACEBOOK,
    icon_code: 'facebook_white'
  },
  {
    name: 'Google News',
    url: SOCIAL_SHARE.GOOGLE_NEWS,
    icon_code: 'google_white'
  }
];
